<template>
    <div id="requirementPage">
        <div class="requireBody">

            <div class="requireContainer row">
                <div class="reContent">
                    <div class="reSuccess">
                        <div class="flex align-center flex-center">
                            <img src="../../assets/shopImg/req/gongxinintubiao.png" alt="">
                            <div class="sec f24 f28">恭喜您,申请提交成功!</div>
                            <!--<div class="sec f24">申请提交成功</div>-->
                        </div>

                        <div class="f14 l20">
                           我们已收到您的入驻申请,1-3个工作日内将会有专业的客户经理与您联系,请保持电话通畅。
                        </div>
                        <div class="row">
                            <div class="submit return" @click="goHomePage">返回首页</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "success",
        methods:{
            goHomePage(){
                this.$router.push({
                    path:'/'
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/change.scss';
    .title{
        height: 100px;
        background-color: #000000;
    }
    .reContent {
        background-color: #fff;
        padding: 128px 300px 200px;
        margin: 0 auto;
        text-align: center;
        .reSuccess {
            width:400px;
            .reTtile {
                margin:0 auto;
            }
            img {
                width: 40px;
                height: 40px;
                margin-right: 19px;
            }
            .sec {
                margin: 20px auto 30px;
            }
            .l20 {
                line-height: 20px;
                margin-bottom: 60px;
            }

            .submit {
                width: 140px;
                height: 40px;
                /*margin-right: 48px;*/
                line-height: 40px;

                color: #fff;
                background: #0db168;
                cursor: pointer;
            }

            .return {
                /*margin-right:0;*/
                margin: 0 auto;
                border:1px solid #0db168;
                background: #fff;
                color: #0db168;
            }
        }
    }
</style>
